<div class="call-component">
  <ng-container *ngIf="authRequest$ | async as authResult; else loadingOrError">
    <mat-drawer-container class="h-100" [hasBackdrop]="false">
      <mat-drawer #drawer [mode]="'side'" class="rounded" position="end">
        <i class="fas fa-angle-right float-left m-2" (click)="drawer.close()" title="Chat schlie&szlig;en"></i>
        <app-chat [drawerEl]="drawer" [chatMessages]="chatMessages$ | async"
                  (sendChatMessage)="sendChatMessage($event)"></app-chat>
      </mat-drawer>
      <mat-drawer-content>
        <div class="container-fluid d-flex h-100 flex-column">
          <div class="video-controls-container">

            <app-video-call-menu
              [hasPeerConnection]="hasPeerConnection$$ | async"
              [drawerEl]="drawer"
              [timer]="timer$ | async"
              [unreadMessageCount]="unreadMessageCount"
              (closePeerConnection)="closeConnection()"
              (openConnection)="initializeCall()"
              (toggleMic)="toggleMic($event)"
              (toggleVideo)="toggleVideo($event)"
              (messagesRead)="resetUnreadMessageCount()"
            ></app-video-call-menu>

            <!-- Connection status indicator -->
            <div *ngIf="connectionState$ | async as state"
                 class="connection-status"
                 [ngClass]="{'connecting': state === 'connecting',
                           'connected': state === 'connected',
                           'disconnected': state === 'disconnected',
                           'failed': state === 'failed',
                           'waiting': state === 'waiting_for_participant'}">
            <span *ngIf="state === 'connecting'">
              <i class="fas fa-sync fa-spin me-2"></i>Verbindung wird hergestellt...
            </span>
              <span *ngIf="state === 'connected'">
              <i class="fas fa-check-circle me-2"></i>Verbunden
            </span>
              <span *ngIf="state === 'disconnected'">
              <i class="fas fa-exclamation-triangle me-2"></i>Verbindung unterbrochen
            </span>
              <span *ngIf="state === 'failed'">
              <i class="fas fa-times-circle me-2"></i>Verbindungsfehler
            </span>
              <span *ngIf="state === 'waiting_for_participant'">
              <i class="fas fa-user-clock me-2"></i>Warten auf weitere Teilnehmer
            </span>
            </div>
          </div>
          <div class="row flex-grow-1 h-100">
            <app-video-call
              class="col-12 d-flex"
              [localStream]="localStream$ | async"
              [remoteStream]="remoteStream$ | async"
              [micEnabled]="micEnabled"
              [videoEnabled]="videoEnabled"
              [connectionState]="connectionState$ | async"
              (reconnectRequest)="handleReconnect()"
            ></app-video-call>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </ng-container>
</div>

<ng-template #loadingOrError>
  <div class="container d-flex">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <ng-container *ngIf="errorObject; else loading">
          <div class="card mt-4">
            <div class="card-header bg-info text-white d-flex align-items-center">
              <i class="fas fa-info-circle me-2"></i>Information
            </div>
            <div class="card-body">
              <div class="card-text pre-wrap">
                Es konnte keine Verbindung hergestellt werden.<br>
                Eventuell ungültige Meeting ID!
              </div>
              <button class="btn btn-primary mt-3 d-flex justify-content-center align-items-center"
                      (click)="retryConnection()">
                <i class="fas fa-redo me-2"></i>Erneut versuchen
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="spinner-container d-flex flex-column align-items-center justify-content-center vh-100">
      <div class="spinner">
        <i class="fas fa-spinner fa-spin fa-3x"></i>
      </div>
      <div class="mt-3">
        Verbindung wird hergestellt...
      </div>
    </div>
  </ng-template>
</ng-template>
