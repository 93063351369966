import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { NgDateDEParser } from '../NgDateDEParser';
import { PracticeInfo } from '../PracticeInfo';
import { SelectedValues } from '../SelectedValues';
import { RoomsService } from '../services/rooms.service';

@Component({
    selector: 'app-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.css'],
    providers: [NgbTimepickerConfig, { provide: NgbDateParserFormatter, useClass: NgDateDEParser }],
    standalone: false
})
export class Step2Component implements OnInit {
  eventtype: string;
  datepickerPlaceholder = null;
  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  maxDate: NgbDateStruct = {
    year: new Date().getFullYear() + 2,
    month: 12,
    day: 31,
  };

  // customTimes = [{ value: { hour: 0, minute: 0 }, time: 'Immer' },
  // { value: { hour: 4, minute: 0 }, time: 'Vormittag' },
  // { value: { hour: 12, minute: 0 }, time: 'Nachmittag' },
  // { value: { hour: 18, minute: 0 }, time: 'Abend' },
  // ];
  customTimes = [
    { value: { fromHour: 0, toHour: 23 }, time: 'Immer' },
    { value: { fromHour: 4, toHour: 11 }, time: 'Vormittag' },
    { value: { fromHour: 12, toHour: 17 }, time: 'Nachmittag' },
    { value: { fromHour: 18, toHour: 23 }, time: 'Abend' },
  ];
  selectedTime = this.customTimes[0];
  hoverDate: boolean;
  hoverMore: boolean;
  @Input() selectedValues: SelectedValues;
  @Input() dates;
  @Input() filteredDates;
  @Input() practiceInfos: any;
  @Input() selectedRoom: any;
  @Input() color;
  @Input() showNext: boolean;
  @Input() isVAMED?: boolean;


  @Output() values = new EventEmitter<SelectedValues>();
  @Output() infos = new EventEmitter<PracticeInfo>();
  @Output() nextStep = new EventEmitter<number>();
  @Output() fetch = new EventEmitter<any>();
  @Output() clearDates = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {
    this.dates = {};
    this.datepickerPlaceholder = this.getPlaceholderDate();
  }

  getPlaceholderDate() {
    const tempDate = moment().add(this.practiceInfos.practice.EnableSameDayBooking ? 0 : 1, 'd');
    return new NgbDate(tempDate.year(), tempDate.month() + 1, tempDate.date());
  }

  currentToDate() {
    const datePickerVal = this.datepickerPlaceholder,
      toDateMoment = moment([datePickerVal.year, datePickerVal.month - 1, datePickerVal.day]).add(30, 'd');
    return toDateMoment.toDate();
  }

  onSelect(start: string, end: string, roomID: number, ownerID: number): void {
    this.selectedValues.Event.Start = start;
    this.selectedValues.Event.End = end;
    this.selectedValues.Event.RoomID = roomID;
    // set owner / user id to assign the task, currently the ownerID here is only given for an EventTypeGroup context at this point
    // otherwise it comes from the url itself and is handled differently
    this.selectedValues.Event.OwnerID = ownerID;
    console.log(this.selectedValues);
    this.values.emit(this.selectedValues);
    this.nextStep.emit(3);
  }

  goTo(step: number): void {
    this.nextStep.emit(step);
  }

  backToStep1() {
    this.clearDates.emit();
    this.goTo(1);
  }

  onTimePick(time: any) {
    // console.log(time.value);
    this.selectedTime = time;
    const filteredDates = JSON.parse(JSON.stringify(this.dates));
    this.filteredDates = filteredDates.map((item) => {
      const tempSlots = item.slots.filter(
        (date) =>
          moment(date.Start).toDate().getHours() >= time.value.fromHour &&
          moment(date.Start).toDate().getHours() <= time.value.toHour
        // ||
        //   (
        //     (moment(date.Start).toDate().getHours() === time.fromHour) &&
        //     (moment(date.Start).toDate().getMinutes() >= time.minute))
        // )
      );
      return { date: item.date, slots: tempSlots };
    });
  }

  onDatePick(date: any) {
    this.fetch.emit({
      selectedDate: moment([date.year, date.month - 1, date.day])
        .add(4, 'hours')
        .toDate(),
    });
  }

  loadNext() {
    this.fetch.emit({ selectedDate: null, append: true });
  }

  slotHovered(slot, isHovered: boolean) {
    slot.isHovered = isHovered;
  }
}
