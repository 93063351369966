<div class="modern-chat-container">
  <div class="chat-header">
    <h5 class="header-title">chat</h5>
    <button class="close-button" (click)="drawerEl.close()">
      <i class="fas fa-times"></i>
    </button>
  </div>

  <div class="chat-messages" (scroll)="onChatScroll($event)">
    <div *ngIf="!chatMessages || chatMessages.length === 0" class="empty-chat">
      <div class="empty-icon">
        <i class="fas fa-comments"></i>
      </div>
      <p class="empty-text">No messages available.<br>Start the conversation!</p>
    </div>

    <div *ngFor="let messageItem of chatMessages; let i = index; let isFirst = first"
         [ngClass]="messageItem.sender === '_self' ? 'message-container sent-message-container' : 'message-container received-message-container'">

      <!-- Time separator -->
      <div *ngIf="messageItem.timestamp && (isFirst || messageItem.timestamp - chatMessages[i-1]?.timestamp > 600000)"
           class="time-separator">
        <div class="separator-line"></div>
        <span class="separator-text">{{ formatTimestamp(messageItem.timestamp) }}</span>
        <div class="separator-line"></div>
      </div>

      <!-- Message bubble -->
      <div class="message-content">
        <div
          [ngClass]="messageItem.sender === '_self' ? 'message-bubble sent-message' : 'message-bubble received-message'"
          [innerHTML]="messageItem.message | linkify">
        </div>

        <!-- Time display -->
        <div class="message-time" *ngIf="messageItem.timestamp">
          {{ formatTimestamp(messageItem.timestamp) }}
        </div>
      </div>
    </div>

    <!-- Auto-scroll helper -->
    <div #scrollBottom></div>
  </div>

  <!-- New message indicator -->
  <div *ngIf="shouldScrollToBottom && userHasScrolledUp" class="new-message-indicator" (click)="scrollToBottom()">
    <i class="fas fa-chevron-down"></i>
    <span>New messages</span>
  </div>

  <div class="chat-input-area">
    <div class="input-container">
      <input
        type="text"
        class="message-input"
        [(ngModel)]="currentMessage"
        placeholder="Write a message..."
        (keyup.enter)="onSendClick(currentMessage)"
      />
      <button
        class="send-button"
        [disabled]="!currentMessage?.trim()"
        (click)="onSendClick(currentMessage)"
      >
        <i class="fas fa-paper-plane"></i>
      </button>
    </div>
  </div>
</div>
