import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ChatMessage {
  message: string;
  sender: string;
  timestamp?: number;
}

export enum ConnectionState {
  New = 'new',
  Connecting = 'connecting',
  Connected = 'connected',
  Disconnected = 'disconnected',
  Failed = 'failed',
  WaitingForParticipant = 'waiting_for_participant'  // New state for waiting for participants
}

interface CustomSocket extends Socket {
  jwt_claims?: {
    role?: string;
    room?: string;
    [key: string]: any;
  };
}

@Injectable({
  providedIn: 'root'
})
export class VideoCallSocketService {
  // Stream subjects and observables
  private localStream$$ = new BehaviorSubject<MediaStream | null>(null);
  public localStream$: Observable<MediaStream | null> = this.localStream$$.asObservable();

  private remoteStream$$ = new BehaviorSubject<MediaStream | null>(null);
  public remoteStream$: Observable<MediaStream | null> = this.remoteStream$$.asObservable();

  // Chat and connection state
  private chatMessages$$ = new BehaviorSubject<ChatMessage[]>([]);
  public chatMessages$: Observable<ChatMessage[]> = this.chatMessages$$.asObservable();

  private connectionState$$ = new BehaviorSubject<ConnectionState>(ConnectionState.New);
  public connectionState$: Observable<ConnectionState> = this.connectionState$$.asObservable();

  // Connection flags
  private isChannelReady = false;
  private isInitiator = false;
  private isStarted = false;
  private isRoomCreator = false;  // Track if this user created the room

  // Connection objects
  private socket: CustomSocket | null = null;
  private pc: RTCPeerConnection | null = null;
  private pcConfig: RTCConfiguration | null = null;

  // Room information
  private roomId: string | null = null;

  // Reconnection handling
  private reconnectInterval: any = null;
  private heartbeatInterval: any = null;

  // Cleanup helper
  private destroy$ = new Subject<void>();

  private remoteMediaReady = false;

  private micEnabledState = true;
  private videoEnabledState = true;

  constructor() {
  }

  /**
   * Initialize the WebRTC connection
   */
  public async initSocket(token: string, pcConfig: RTCConfiguration, localStream: MediaStream): Promise<void> {
    try {
      // Store configuration and local stream
      this.pcConfig = pcConfig;

      // stored mic and video states
      this.applyMediaStates(localStream);

      this.localStream$$.next(localStream);
      this.connectionState$$.next(ConnectionState.Connecting);

      // Parse JWT to extract role and room
      let role = null;
      try {
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        role = jwtToken.role;
        this.roomId = jwtToken.room;
        this.log(`User role from JWT is ${role}, room: ${this.roomId}`);
      } catch (error) {
        this.log('Error parsing JWT token:', error);
      }

      // Initialize socket connection
      this.socket = io(environment.patientConnectUrl, {
        path: '/call',
        query: { token },
        reconnection: true,           // Enable reconnection
        reconnectionAttempts: 15,     // Increased attempts for longer waiting
        reconnectionDelay: 1000,      // Match server config
        timeout: 30000,               // Increased timeout
        transports: ['websocket', 'polling']
      });

      // Add connection monitoring
      this.socket.on('connect', () => {
        this.log('Socket connected successfully');
        this.connectionState$$.next(ConnectionState.Connecting);

        // Start heartbeat to keep socket alive
        this.startHeartbeat();
      });

      this.socket.on('peer_connection_issue', (data: any) => {
        this.log(`Remote peer having connection issues: ${data.role}, reason: ${data.reason}`);
        // Don't immediately disconnect, give them a chance to reconnect
        this.connectionState$$.next(ConnectionState.Connecting);
      });

      this.socket.on('peer_reconnected', (data: any) => {
        this.log(`Remote peer reconnected: ${data.role}`);
        this.connectionState$$.next(ConnectionState.Connecting);

        // If we're the initiator, restart the connection
        if (this.isInitiator && !this.isStarted) {
          setTimeout(() => {
            this.startConnection();
          }, 1000);
        }
      });

      // Handle duplicate role notification
      this.socket.on('duplicate_role', (data: any) => {
        this.log(`Duplicate role error: ${data.message}`);
        this.connectionState$$.next(ConnectionState.Failed);
      });

      // Update the disconnect event handler
      this.socket.on('disconnect', (reason: string) => {
        this.log(`Disconnected: ${reason}.`);

        if (reason === 'transport close' || reason === 'io server disconnect' || reason === 'ping timeout') {
          // These are potentially recoverable - change state but don't clean up yet
          this.connectionState$$.next(ConnectionState.Connecting);

          // Start reconnection attempt
          this.startReconnection();
        } else {
          // Non-recoverable disconnect
          this.connectionState$$.next(ConnectionState.Disconnected);
          this.cleanupConnection(false);
        }
      });

      // Add reconnection events
      this.socket.on('reconnect_attempt', (attemptNumber: number) => {
        this.log(`Attempting to reconnect (${attemptNumber})`);
      });

      this.socket.on('reconnect', (attemptNumber: number) => {
        this.log(`Reconnected after ${attemptNumber} attempts`);
        this.connectionState$$.next(ConnectionState.Connecting);

        // Clear any previous reconnection timer
        this.clearReconnectionTimer();

        // If we're the initiator and haven't started, try to restart
        if (this.isInitiator && !this.isStarted) {
          setTimeout(() => {
            this.startConnection();
          }, 1000);
        }
      });

      this.socket.on('reconnect_error', (error: any) => {
        this.log('Reconnection error:', error);
      });

      this.socket.on('reconnect_failed', () => {
        this.log('Failed to reconnect after all attempts');
        this.connectionState$$.next(ConnectionState.Failed);
      });

      await this.setupSocketListeners(role);

      // Remaining setup
      this.log('Socket initialized successfully');
    } catch (error) {
      this.log('Socket initialization error:', error);
      this.connectionState$$.next(ConnectionState.Failed);
      throw error;
    }
  }

  /**
   * Start a heartbeat to keep the socket connection alive
   */
  private startHeartbeat(): void {
    // Clear any existing heartbeat
    this.clearHeartbeat();

    // Send a heartbeat every 15 seconds instead of 30
    this.heartbeatInterval = setInterval(() => {
      if (this.socket && this.socket.connected) {
        this.socket.emit('heartbeat', {
          roomId: this.roomId,
          tracks: {
            audio: this.localStream$$.getValue()?.getAudioTracks().length > 0,
            video: this.localStream$$.getValue()?.getVideoTracks().length > 0
          },
          connState: this.pc ? this.pc.connectionState : null,
          iceState: this.pc ? this.pc.iceConnectionState : null
        });
        this.log('Heartbeat sent with connection state');
      }
    }, 15000);
  }

  /**
   * Clear the heartbeat interval
   */
  private clearHeartbeat(): void {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  /**
   * Start reconnection attempts
   */
  private startReconnection(): void {
    // Clear any previous timer
    this.clearReconnectionTimer();

    // Attempt to reconnect every 5 seconds
    this.reconnectInterval = setInterval(() => {
      if (!this.socket || !this.socket.connected) {
        this.log('Attempting manual reconnection');

        if (this.socket) {
          this.socket.connect();
        }
      } else {
        this.clearReconnectionTimer();
      }
    }, 5000);
  }

  /**
   * Clear reconnection timer
   */
  private clearReconnectionTimer(): void {
    if (this.reconnectInterval) {
      clearInterval(this.reconnectInterval);
      this.reconnectInterval = null;
    }
  }

  /**
   * Set up all socket event listeners
   */
  private reconnectAsClient(room: string): void {
    this.log('Attempting to reconnect as client to room ' + room);

    let reconnectAttempt = 0;
    const maxAttempts = 10; // Increased max attempts

    const attemptReconnect = () => {
      reconnectAttempt++;
      this.log(`Client reconnection attempt ${reconnectAttempt}/${maxAttempts}`);

      if (reconnectAttempt > maxAttempts) {
        this.log('Max reconnection attempts reached');
        this.connectionState$$.next(ConnectionState.Failed);
        return;
      }

      this.sendMessage('got user media');

      setTimeout(() => {
        if (this.socket && !this.isChannelReady) {
          attemptReconnect();
        }
      }, 2000);
    };

    // Start attempting reconnection
    setTimeout(attemptReconnect, 2000);
  }

  private setupRemainingListeners(): void {
    if (!this.socket) {
      return;
    }

    // Server logging
    this.socket.on('log', (array: any[]) => {
      this.log('Server log:', ...array);
    });

    // Remote peer leaving
    this.socket.on('bye', (data?: any) => {
      this.log('Received bye from remote peer', data);
      this.handleRemoteHangup();
    });

    // Connection errors
    this.socket.on('connect_error', (error: any) => {
      this.log('Connection error:', error);
      this.connectionState$$.next(ConnectionState.Failed);
    });

    // General socket errors
    this.socket.on('error', (error: any) => {
      this.log('Socket error:', error);
    });

    // Listen for heartbeat responses
    this.socket.on('heartbeat_response', (data: any) => {
      this.log('Heartbeat response received:', data);
      // If we're waiting for a participant and we're the room creator
      if (this.isRoomCreator &&
        this.connectionState$$.getValue() === ConnectionState.WaitingForParticipant &&
        data.clientsCount > 1) {
        // Another participant has joined - start connection
        this.log('Another participant detected via heartbeat - restarting connection process');
        this.isChannelReady = true;
        this.startConnection();
      }
    });
  }


  /**
   * Handle a remote offer to establish connection
   */
  private async handleRemoteOffer(message: RTCSessionDescriptionInit): Promise<void> {
    if (!this.pc) {
      return;
    }

    try {
      this.log('Setting remote description from offer');
      await this.pc.setRemoteDescription(new RTCSessionDescription(message));
      this.log('Set remote description from offer successfully');
      await this.createAndSendAnswer();

      // Set channel as ready once we've processed an offer
      this.isChannelReady = true;
    } catch (error) {
      this.log('Error handling remote offer:', error);
      this.connectionState$$.next(ConnectionState.Failed);
    }
  }

  /**
   * Handle a remote answer to our offer
   */
  private async handleRemoteAnswer(message: RTCSessionDescriptionInit): Promise<void> {
    if (!this.pc) {
      return;
    }

    try {
      await this.pc.setRemoteDescription(new RTCSessionDescription(message));
      this.log('Set remote description from answer successfully');

      // Connection should be established now
      this.connectionState$$.next(ConnectionState.Connected);
    } catch (error) {
      this.log('Error handling remote answer:', error);
      this.connectionState$$.next(ConnectionState.Failed);
    }
  }

  /**
   * Handle a remote ICE candidate
   */
  private async handleRemoteIceCandidate(message: any): Promise<void> {
    if (!this.pc) {
      return;
    }

    try {
      const candidate = new RTCIceCandidate({
        sdpMLineIndex: message.label,
        candidate: message.candidate
      });

      await this.pc.addIceCandidate(candidate);
      this.log('Added ICE candidate successfully');
    } catch (error) {
      this.log('Error adding received ICE candidate:', error);
    }
  }

  /**
   * Handle incoming chat message
   */
  private handleChatMessage(message: any): void {
    const chatMessages = [
      ...this.chatMessages$$.getValue(),
      {
        message: message.message,
        sender: '_remote',
        timestamp: Date.now()
      }
    ];
    this.chatMessages$$.next(chatMessages);
  }

  /**
   * Start WebRTC connection if conditions are met
   */
  private startConnection(): void {
    const localStream = this.localStream$$.getValue();
    this.log('Attempting to start connection:', {
      isStarted: this.isStarted,
      hasLocalStream: !!localStream,
      isChannelReady: this.isChannelReady,
      isInitiator: this.isInitiator
    });

    if (!this.isStarted && localStream && this.isChannelReady) {
      this.log('Creating peer connection');
      this.createPeerConnection();

      if (this.pc && localStream) {
        this.addLocalStreamTracks(localStream);
      }

      this.isStarted = true;

      // Get role from socket if available
      const currentRole = this.socket?.jwt_claims?.role;

      if (currentRole === 'host' || this.isInitiator) {
        this.log('Creating offer as host/initiator');
        // Ensure initiator flag matches role
        this.isInitiator = true;

        // Create offer immediately - don't delay
        this.createAndSendOffer();
      } else {
        this.log('Waiting for offer as client/non-initiator');
      }
    } else {
      this.log('Not starting connection - conditions not met', {
        isStarted: this.isStarted,
        hasLocalStream: !!localStream,
        isChannelReady: this.isChannelReady
      });
    }
  }

  /**
   * Add local media tracks to the peer connection
   */
  private addLocalStreamTracks(stream: MediaStream): void {
    if (!this.pc) {
      return;
    }

    stream.getTracks().forEach(track => {
      if (this.pc && stream) {
        this.pc.addTrack(track, stream);
        this.log(`Added ${track.kind} track to peer connection`);
      }
    });
  }

  /**
   * Create the RTCPeerConnection with proper event handlers
   */
  private createPeerConnection(): void {
    try {
      if (!this.pcConfig) {
        throw new Error('RTCPeerConnection configuration is missing');
      }

      this.pc = new RTCPeerConnection(this.pcConfig);

      if (this.pc) {
        // Set up all event handlers
        this.pc.onicecandidate = (event) => this.handleIceCandidate(event);
        this.pc.ontrack = (event) => this.handleTrackAdded(event);
        this.pc.oniceconnectionstatechange = () => this.handleConnectionStateChange();
        this.pc.onicegatheringstatechange = () => this.handleIceGatheringStateChange();
        this.pc.onsignalingstatechange = () => this.handleSignalingStateChange();
        this.pc.onconnectionstatechange = () => this.handleRTCConnectionStateChange();
      }

      this.log('Created RTCPeerConnection');
    } catch (error) {
      this.log('Failed to create PeerConnection:', error);
      this.connectionState$$.next(ConnectionState.Failed);
      throw error;
    }
  }

  /**
   * Handle ICE candidate generation
   */
  private handleIceCandidate(event: RTCPeerConnectionIceEvent): void {
    if (event.candidate) {
      this.log('ICE candidate generated:', event.candidate);
      this.sendMessage({
        type: 'candidate',
        label: event.candidate.sdpMLineIndex,
        id: event.candidate.sdpMid,
        candidate: event.candidate.candidate
      });
    } else {
      this.log('End of ICE candidates');
    }
  }

  /**
   * Handle new tracks being added from remote peer
   */
  private handleTrackAdded(event: RTCTrackEvent): void {
    if (event.streams && event.streams[0]) {
      this.log('Remote track added:', event.track.kind);

      // Monitor track state changes
      event.track.onmute = () => {
        this.log(`Remote ${event.track.kind} track muted`);
      };

      event.track.onunmute = () => {
        this.log(`Remote ${event.track.kind} track unmuted`);
      };

      event.track.onended = () => {
        this.log(`Remote ${event.track.kind} track ended`);
        // Try reconnecting the specific track if it ends unexpectedly
        if (this.pc && this.pc.connectionState === 'connected') {
          this.log('Attempting to recover ended track');
          this.attemptTrackRecovery(event.track.kind);
        }
      };

      const stream = event.streams[0];
      this.remoteStream$$.next(stream);

      // Only update connection state after we have at least one audio and one video track
      const videoTracks = stream.getVideoTracks();
      const audioTracks = stream.getAudioTracks();

      this.log(`Remote stream has ${videoTracks.length} video tracks and ${audioTracks.length} audio tracks`);

      if (videoTracks.length > 0 && audioTracks.length > 0) {
        this.connectionState$$.next(ConnectionState.Connected);
      }

      // Verify tracks are active
      setTimeout(() => {
        this.verifyRemoteTracksActive(stream);
      }, 3000);
    }
  }

  /**
   * Monitor ICE connection state
   */
  private handleConnectionStateChange(): void {
    if (!this.pc) {
      return;
    }

    this.log('ICE connection state changed:', this.pc.iceConnectionState);

    switch (this.pc.iceConnectionState) {
      case 'connected':
      case 'completed':
        this.connectionState$$.next(ConnectionState.Connected);
        break;
      case 'failed':
        this.connectionState$$.next(ConnectionState.Failed);
        this.attemptIceRestart();
        break;
      case 'disconnected':
        // Only update if we're not waiting for a participant
        if (this.connectionState$$.getValue() !== ConnectionState.WaitingForParticipant) {
          this.connectionState$$.next(ConnectionState.Disconnected);
        }
        break;
    }
  }

  /**
   * Monitor ICE gathering state
   */
  private handleIceGatheringStateChange(): void {
    if (!this.pc) {
      return;
    }
    this.log('ICE gathering state changed:', this.pc.iceGatheringState);
  }

  /**
   * Monitor signaling state
   */
  private handleSignalingStateChange(): void {
    if (!this.pc) {
      return;
    }
    this.log('Signaling state changed:', this.pc.signalingState);

    if (this.pc.signalingState === 'closed') {
      // Only update if we're not waiting for a participant
      if (this.connectionState$$.getValue() !== ConnectionState.WaitingForParticipant) {
        this.connectionState$$.next(ConnectionState.Disconnected);
      }
    }
  }

  /**
   * Monitor RTCPeerConnection state
   */
  private handleRTCConnectionStateChange(): void {
    if (!this.pc) {
      return;
    }
    this.log('Connection state changed:', this.pc.connectionState);

    switch (this.pc.connectionState) {
      case 'connected':
        this.connectionState$$.next(ConnectionState.Connected);
        break;
      case 'disconnected':
      case 'closed':
        // Only update if we're not waiting for a participant
        if (this.connectionState$$.getValue() !== ConnectionState.WaitingForParticipant) {
          this.connectionState$$.next(ConnectionState.Disconnected);
        }
        break;
      case 'failed':
        this.connectionState$$.next(ConnectionState.Failed);
        break;
    }
  }

  /**
   * Attempt ICE restart if connection fails
   */
  private async attemptIceRestart(): Promise<void> {
    if (!this.pc || !this.isInitiator) {
      return;
    }

    try {
      this.log('Attempting ICE restart');
      await this.createAndSendOffer(true);
    } catch (error) {
      this.log('ICE restart failed:', error);
    }
  }

  /**
   * Create and send an offer to the remote peer
   */
  private createAndSendOffer(iceRestart = false): Promise<void> {
    if (!this.pc) {
      return Promise.reject(new Error('No peer connection available'));
    }

    return new Promise<void>((resolve, reject) => {
      try {
        // Ensure we have a longer timeout to gather ICE candidates
        setTimeout(async () => {
          try {
            const offerOptions: RTCOfferOptions = {
              offerToReceiveAudio: true,
              offerToReceiveVideo: true,
              iceRestart
            };

            const offer = await this.pc.createOffer(offerOptions);
            await this.pc.setLocalDescription(offer);

            // Wait a bit more to collect more ICE candidates before sending offer
            setTimeout(() => {
              this.log('Created and set local offer description');
              if (this.pc && this.pc.localDescription) {
                this.sendMessage(this.pc.localDescription);
                this.log('Sent local description to peer');
                resolve();
              } else {
                reject(new Error('No local description available'));
              }
            }, 1000); // Wait for ICE candidates
          } catch (error) {
            this.log('Error creating offer:', error);
            this.connectionState$$.next(ConnectionState.Failed);
            reject(error);
          }
        }, 500);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Create and send an answer to a received offer
   */
  private async createAndSendAnswer(): Promise<void> {
    if (!this.pc) {
      return;
    }

    try {
      const answer = await this.pc.createAnswer();
      await this.pc.setLocalDescription(answer);

      this.log('Created and set local answer description');
      this.sendMessage(this.pc.localDescription);
    } catch (error) {
      this.log('Error creating answer:', error);
      this.connectionState$$.next(ConnectionState.Failed);
    }
  }

  /**
   * Send a message to the signaling server
   */
  private sendMessage(message: any): void {
    this.log('Sending message:', message);
    if (this.socket) {
      this.socket.emit('message', message);
    }
  }

  /**
   * Close the WebRTC connection
   */
  public closeConnection(): void {
    this.log('Closing connection explicitly');

    // Clear any intervals
    this.clearReconnectionTimer();
    this.clearHeartbeat();

    // Always send bye signal if we have a socket connection
    if (this.socket && this.socket.connected) {
      this.log('Sending explicit bye signal');
      this.socket.emit('bye');

      // Give some time for the message to be sent before cleanup
      setTimeout(() => {
        this.log('Cleaning up after bye signal');
        this.cleanupConnection(true);
      }, 500);
    } else {
      this.log('No socket to send bye signal, cleaning up directly');
      this.cleanupConnection(true);
    }
  }

  /**
   * Handle remote peer hanging up
   */
  private handleRemoteHangup(): void {
    this.log('Remote peer hung up - cleaning up connection');

    if (this.pc && (this.pc.connectionState === 'connecting' || this.pc.iceConnectionState === 'checking')) {
      this.log('Ignoring bye during connection establishment - likely a false signal');
      return; // Don't process the hang up during initial connection
    }

    // If we're the room creator, go back to waiting state
    if (this.isRoomCreator) {
      this.connectionState$$.next(ConnectionState.WaitingForParticipant);
    } else {
      this.connectionState$$.next(ConnectionState.Disconnected);
    }

    // Clean up but don't close socket
    this.cleanupConnection(false);

    // Reset flags
    this.isStarted = false;
    this.isChannelReady = false;

    // Keep the initiator flag as is for room creator
    if (!this.isRoomCreator) {
      this.isInitiator = true;
    }

    // Remove remote stream
    this.remoteStream$$.next(null);
  }

  /**
   * Clean up all connection resources
   */
  private cleanupConnection(closeSocket: boolean): void {
    this.log(`Cleaning up connection (closeSocket: ${closeSocket})`);

    // Reset connection flags
    this.isStarted = false;
    this.isChannelReady = false;

    // Close and clean up peer connection
    if (this.pc) {
      try {
        // Remove all event listeners
        this.pc.onicecandidate = null;
        this.pc.ontrack = null;
        this.pc.oniceconnectionstatechange = null;
        this.pc.onicegatheringstatechange = null;
        this.pc.onsignalingstatechange = null;
        this.pc.onconnectionstatechange = null;

        // Close the connection
        this.pc.close();
        this.log('Peer connection closed successfully');
      } catch (error) {
        this.log('Error closing peer connection:', error);
      }
      this.pc = null;
    }

    // Reset remote stream
    this.remoteStream$$.next(null);

    // Close socket if requested
    if (closeSocket && this.socket) {
      this.log('Disconnecting socket');
      this.socket.disconnect();
      this.socket = null;
    }

    // Update connection state - but keep waiting if we're the room creator
    if (!this.isRoomCreator || closeSocket) {
      this.connectionState$$.next(ConnectionState.Disconnected);
    }
  }

  /**
   * Clean up all resources when service is destroyed
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.closeConnection();
  }

  /**
   * Enable or disable microphone
   */
  public setMicEnabled(enabled: boolean): void {
    this.micEnabledState = enabled; // Store the state
    const localStream = this.localStream$$.getValue();
    if (localStream) {
      localStream.getAudioTracks().forEach((track) => {
        track.enabled = enabled;
        this.log(`Microphone ${enabled ? 'enabled' : 'disabled'}`);
      });
    }
  }

  /**
   * Enable or disable camera
   */
  public setVideoEnabled(enabled: boolean): void {
    this.videoEnabledState = enabled; // Store the state
    const localStream = this.localStream$$.getValue();
    if (localStream) {
      localStream.getVideoTracks().forEach((track) => {
        track.enabled = enabled;
        this.log(`Camera ${enabled ? 'enabled' : 'disabled'}`);
      });
    }
  }

  /**
   * Send a chat message
   */
  public sendChatMessage(message: string): void {
    if (!message.trim()) {
      return;
    }

    const newMessage: ChatMessage = {
      message,
      sender: '_self',
      timestamp: Date.now()
    };

    const chatMessages = [...this.chatMessages$$.getValue(), newMessage];
    this.chatMessages$$.next(chatMessages);

    if (this.socket) {
      this.socket.emit('message', { type: 'chat', message });
    }
  }

  /**
   * Apply stored mic and video states to a stream
   */
  private applyMediaStates(stream: MediaStream): void {
    if (!stream) {
      return;
    }

    // Apply microphone state
    stream.getAudioTracks().forEach(track => {
      track.enabled = this.micEnabledState;
      this.log(`Applied stored mic state to new track: ${this.micEnabledState ? 'enabled' : 'disabled'}`);
    });

    // Apply video state
    stream.getVideoTracks().forEach(track => {
      track.enabled = this.videoEnabledState;
      this.log(`Applied stored video state to new track: ${this.videoEnabledState ? 'enabled' : 'disabled'}`);
    });
  }

  /**
   * Update the setupSocketListeners method to handle refresh/reconnection
   */
  private async setupSocketListeners(role: string | null): Promise<void> {
    if (!this.socket) {
      return;
    }

    // Clear any previous event listeners
    this.socket.off('created');
    this.socket.off('full');
    this.socket.off('join');
    this.socket.off('joined');
    this.socket.off('log');
    this.socket.off('message');
    this.socket.off('bye');
    this.socket.off('ready');

    // Create a promise to know when initial setup is complete
    return new Promise((resolve) => {
      // Set initiator flag immediately based on role
      if (role === 'host') {
        this.isInitiator = true;
        this.log('Setting initiator=true based on host role');
      } else if (role === 'client') {
        this.isInitiator = false;
        this.log('Setting initiator=false based on client role');
      }

      // When room is created (both host and client will get this)
      this.socket.on('created', (room: string) => {
        this.log('Created room ' + room);

        if (role === 'host') {
          this.log('We are the HOST and created the room - becoming initiator');
          this.isInitiator = true;
          this.isRoomCreator = true;

          // Update state to indicate waiting for participant
          this.connectionState$$.next(ConnectionState.WaitingForParticipant);

          // Send media signal
          setTimeout(() => {
            this.sendMessage('got user media');
          }, 1000);

          // Start periodic room status check
          if (this.socket) {
            setInterval(() => {
              if (this.socket && this.socket.connected) {
                this.socket.emit('check_room_status', { room });
              }
            }, 5000);
          }

          resolve();
        } else if (role === 'client') {
          this.log('We are the CLIENT but received created - waiting for host connection');
          this.isInitiator = false;

          this.reconnectAsClient(room);

          resolve();
        }
      });

      // Room is full
      this.socket.on('full', (room: string) => {
        this.log('Room ' + room + ' is full');
        this.connectionState$$.next(ConnectionState.Failed);
        resolve();
      });

      // Room status update
      this.socket.on('room_status', (data: any) => {
        this.log('Room status update:', data);

        // If we're waiting and there are multiple clients, we should restart connection
        if (this.isRoomCreator &&
          this.connectionState$$.getValue() === ConnectionState.WaitingForParticipant &&
          data.clientsCount > 1) {
          this.log('Another participant detected - restarting connection process');
          this.isChannelReady = true;
          this.startConnection();
        }
      });

      // When another peer wants to join
      this.socket.on('join', (room: string) => {
        this.log('Another peer made a request to join room ' + room);

        // This is critical for reconnection - always set channel ready when we get join
        this.isChannelReady = true;

        // If we're waiting for a participant, change state to connecting
        if (this.connectionState$$.getValue() === ConnectionState.WaitingForParticipant) {
          this.connectionState$$.next(ConnectionState.Connecting);
        }

        // If we're already connected, we need to clean up and restart
        if (this.isStarted) {
          this.log('Restarting connection after peer refresh');
          this.cleanupLocalConnection();
          this.isStarted = false;
          this.connectionState$$.next(ConnectionState.Connecting);
        }

        setTimeout(() => {
          this.sendMessage('got user media');
        }, 500);

        resolve();
      });

      this.socket.on('joined', (room: string) => {
        this.log('We joined room: ' + room);

        if (role === 'host') {
          this.isInitiator = true;
          this.log('HOST joined room - setting as initiator');
        } else if (role === 'client') {
          this.isInitiator = false;
          this.log('CLIENT joined room - not setting as initiator');
        }

        this.isChannelReady = true;

        setTimeout(() => {
          this.sendMessage('got user media');
        }, 500);

        resolve();
      });

      // Room is ready (both peers are present)
      this.socket.on('ready', () => {
        this.log('Room is ready for signaling');

        // Set channel as ready
        this.isChannelReady = true;

        // If we were waiting for participants, change state
        if (this.connectionState$$.getValue() === ConnectionState.WaitingForParticipant) {
          this.connectionState$$.next(ConnectionState.Connecting);
        }

        // Start connection based on role
        setTimeout(() => {
          if (role === 'host' && !this.isStarted) {
            this.log('Host starting connection after ready');
            this.startConnection();
          }
        }, 1000);

        resolve();
      });

      // Set up message handling
      this.socket.on('message', (message: any) => {
        this.handleMessage(message);
      });

      // The rest of the event listeners
      this.setupRemainingListeners();

      setTimeout(() => resolve(), 5000);
    });
  }

  /**
   * method to clean up the local connection without closing the socket
   */
  private cleanupLocalConnection(): void {
    this.log('Cleaning up local connection for reconnection');

    // Close and clean up peer connection
    if (this.pc) {
      try {
        // Remove all event listeners
        this.pc.onicecandidate = null;
        this.pc.ontrack = null;
        this.pc.oniceconnectionstatechange = null;
        this.pc.onicegatheringstatechange = null;
        this.pc.onsignalingstatechange = null;
        this.pc.onconnectionstatechange = null;

        // Close the connection
        this.pc.close();
        this.log('Peer connection closed for reconnection');
      } catch (error) {
        this.log('Error cleaning up peer connection:', error);
      }
      this.pc = null;
    }

    // Reset remote stream but keep local stream
    this.remoteStream$$.next(null);
  }

  /**
   * Improved handleMessage to better handle reconnection
   */
  private handleMessage(message: any): void {
    this.log('Client received message:', message);

    if (message === 'got user media') {
      this.log('Remote peer has media ready');
      this.log('Connection state after got user media:', {
        isInitiator: this.isInitiator,
        isStarted: this.isStarted,
        isChannelReady: this.isChannelReady,
        role: this.socket?.jwt_claims?.role || 'unknown'
      });

      // Set channel as ready when we get this message
      this.isChannelReady = true;

      // If we're the host, force connection start if not started
      const role = this.socket?.jwt_claims?.role;
      if (role === 'host' && !this.isStarted) {
        this.log('Forcing connection start as host after got user media');
        setTimeout(() => {
          this.startConnection();
        }, 1000);
      }

      // Original code - use initiator flag as fallback
      if (this.isInitiator && !this.isStarted) {
        this.startConnection();
      }
    } else if (message.type === 'offer') {
      this.log('Received offer from remote peer');

      // If we get an offer but we're already started, we need to reset
      // This happens when the other peer refreshes
      if (this.isStarted) {
        this.log('Received offer while already started - peer likely refreshed');
        this.cleanupLocalConnection();
        this.isStarted = false;
      }

      // Start the connection if we're not the initiator
      if (!this.isInitiator && !this.isStarted) {
        this.startConnection();
      }

      if (this.pc) {
        this.handleRemoteOffer(message);
      }
    } else if (message.type === 'answer' && this.isStarted && this.pc) {
      this.log('Received answer from remote peer');
      this.handleRemoteAnswer(message);
    } else if (message.type === 'candidate' && this.isStarted && this.pc) {
      this.log('Received ICE candidate from remote peer');
      this.handleRemoteIceCandidate(message);
    } else if (message.type === 'chat') {
      this.handleChatMessage(message);
    } else if (message === 'bye' && this.isStarted) {
      // Debug the current connection state at bye
      if (this.pc) {
        this.log('Connection state at bye:', {
          connectionState: this.pc.connectionState,
          iceConnectionState: this.pc.iceConnectionState,
          signalingState: this.pc.signalingState,
          iceGatheringState: this.pc.iceGatheringState
        });
      }

      // Ignore bye during connection establishment
      if (this.pc &&
        (this.pc.connectionState === 'connecting' ||
          this.pc.iceConnectionState === 'checking' ||
          this.pc.connectionState === 'new' ||
          this.pc.iceConnectionState === 'new')) {
        this.log('Ignoring bye during connection establishment');
        return;
      }

      this.handleRemoteHangup();
    }
  }

  /**
   * Logging utility
   */
  private log(...args: any[]): void {
    if (!environment.production) {
      console.log('[WebRTC]', ...args);
    }
  }

  private verifyRemoteTracksActive(stream: MediaStream): void {
    const videoTracks = stream.getVideoTracks();
    const audioTracks = stream.getAudioTracks();

    let allTracksActive = true;

    if (videoTracks.length > 0) {
      const videoActive = videoTracks[0].readyState === 'live';
      this.log('Video track active:', videoActive);
      allTracksActive = allTracksActive && videoActive;
    }

    if (audioTracks.length > 0) {
      const audioActive = audioTracks[0].readyState === 'live';
      this.log('Audio track active:', audioActive);
      allTracksActive = allTracksActive && audioActive;
    }

    if (!allTracksActive && this.pc && this.pc.connectionState === 'connected') {
      this.log('Not all tracks active, attempting recovery');
      this.attemptTrackRecovery();
    }
  }

  private attemptTrackRecovery(kind?: string): void {
    if (this.pc && this.pc.connectionState === 'connected') {
      this.log(`Attempting track recovery via renegotiation`);
      this.createAndSendOffer(true); // Force ICE restart
    }
  }
}
