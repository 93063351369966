import { Pipe, PipeTransform } from '@angular/core';
import linkifyStr from 'linkifyjs/string';

/**
 * Transforms URLs in a string into clickable HTML links
 * Usage: {{ textWithUrls | linkify }}
 */
@Pipe({
  name: 'linkify',
  standalone: false,
  pure: true
})
export class LinkifyPipe implements PipeTransform {
  /**
   * Transform method that converts plain text URLs into HTML anchor tags
   * @param value - The input string containing URLs to be linkified
   * @param options - Optional configuration for linkify
   * @returns HTML string with URLs converted to clickable links
   */
  transform(value: string | null | undefined, options?: {
    defaultProtocol?: string;
    target?: string;
    rel?: string;
    className?: string;
  }): string {
    if (!value) {
      return '';
    }

    const defaultOptions = {
      defaultProtocol: 'https',
      target: '_blank',
      rel: 'noopener noreferrer',
      ...options
    };

    return linkifyStr(value, defaultOptions);
  }
}
