import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent implements OnInit {
  // title = 'app';

  ngOnInit () {
    window.addEventListener('dragover', function (e) {
      if (e) { e.preventDefault(); }
    }, false);
    window.addEventListener('drop', function (e) {
      if (e) { e.preventDefault(); }
    }, false);
  }
}
