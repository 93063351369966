import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { ConnectionState } from '../../services/video-call-socket.service';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.css'],
  standalone: false
})
export class VideoCallComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('localVideo') localVideoEl!: ElementRef<HTMLVideoElement>;
  @ViewChild('remoteVideo') remoteVideoEl!: ElementRef<HTMLVideoElement>;

  @Input() localStream: MediaStream | null = null;
  @Input() remoteStream: MediaStream | null = null;
  @Input() micEnabled = true;
  @Input() videoEnabled = true;
  @Input() connectionState: ConnectionState | null = null;

  // Event emitter for reconnect request
  @Output() reconnectRequest = new EventEmitter<void>();

  localVideoLoaded = false;
  remoteVideoLoaded = false;
  connectionError = false;

  // Flag to determine if we're waiting for participants to join
  get isWaitingForParticipant(): boolean {
    return this.connectionState === ConnectionState.WaitingForParticipant;
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updateVideoElements();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // When inputs change, update the video elements if they're available
    if (changes['localStream'] || changes['remoteStream']) {
      // Use timeout to ensure view is ready
      setTimeout(() => this.updateVideoElements(), 0);
    }

    // Update connection error state based on connection state
    if (changes['connectionState']) {
      this.connectionError = this.connectionState === ConnectionState.Failed;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    // Clean up video elements to prevent memory leaks
    this.cleanupVideoElements();
  }

  /**
   * Handle reconnect button click
   */
  onReconnectClick(): void {
    this.reconnectRequest.emit();
  }

  /**
   * Update video elements with current streams
   */
  private updateVideoElements(): void {
    // Update local video element if available
    if (this.localVideoEl?.nativeElement && this.localStream) {
      try {
        // Only set if different to prevent flickering
        if (this.localVideoEl.nativeElement.srcObject !== this.localStream) {
          // Create a cloned stream without audio tracks to prevent echo
          const clonedStream = this.createVideoOnlyStream(this.localStream);
          this.localVideoEl.nativeElement.srcObject = clonedStream;

          // Add event listeners for local video
          this.localVideoEl.nativeElement.onloadedmetadata = () => {
            this.localVideoLoaded = true;
            this.cdr.detectChanges();
          };

          this.localVideoEl.nativeElement.onerror = () => {
            console.error('Error loading local video');
            this.localVideoLoaded = false;
            this.cdr.detectChanges();
          };
        }
      } catch (err) {
        console.error('Error setting local video source:', err);
      }
    } else if (this.localVideoEl?.nativeElement && !this.localStream) {
      this.localVideoEl.nativeElement.srcObject = null;
      this.localVideoLoaded = false;
    }

    // Update remote video element if available
    if (this.remoteVideoEl?.nativeElement && this.remoteStream) {
      try {
        // Only set if different to prevent flickering
        if (this.remoteVideoEl.nativeElement.srcObject !== this.remoteStream) {
          this.remoteVideoEl.nativeElement.srcObject = this.remoteStream;

          // Add event listeners for remote video
          this.remoteVideoEl.nativeElement.onloadedmetadata = () => {
            this.remoteVideoLoaded = true;
            this.connectionError = false;
            this.cdr.detectChanges();
          };

          this.remoteVideoEl.nativeElement.onerror = () => {
            console.error('Error loading remote video');
            this.remoteVideoLoaded = false;
            this.connectionError = true;
            this.cdr.detectChanges();
          };
        }
      } catch (err) {
        console.error('Error setting remote video source:', err);
        this.connectionError = true;
      }
    } else if (this.remoteVideoEl?.nativeElement && !this.remoteStream) {
      this.remoteVideoEl.nativeElement.srcObject = null;
      this.remoteVideoLoaded = false;
    }
  }

  /**
   * Creates a new MediaStream with only video tracks from the original stream
   * This prevents audio feedback when local stream is displayed
   */
  private createVideoOnlyStream(originalStream: MediaStream): MediaStream {
    const videoOnlyStream = new MediaStream();

    // Only add video tracks, skip audio tracks
    originalStream.getVideoTracks().forEach(track => {
      videoOnlyStream.addTrack(track);
    });

    return videoOnlyStream;
  }

  /**
   * Clean up video elements
   */
  private cleanupVideoElements(): void {
    if (this.localVideoEl?.nativeElement) {
      this.localVideoEl.nativeElement.srcObject = null;
      this.localVideoEl.nativeElement.onloadedmetadata = null;
      this.localVideoEl.nativeElement.onerror = null;
    }

    if (this.remoteVideoEl?.nativeElement) {
      this.remoteVideoEl.nativeElement.srcObject = null;
      this.remoteVideoEl.nativeElement.onloadedmetadata = null;
      this.remoteVideoEl.nativeElement.onerror = null;
    }
  }

  /**
   * Get CSS classes for local video based on state
   */
  getLocalVideoClasses(): { [key: string]: boolean } {
    return {
      'local-video': true,
      'video-disabled': !this.videoEnabled,
      'rounded-sm': true,
      'shadow-sm': true,
      'w-100': true,
      'd-none': !this.localStream
    };
  }

  /**
   * Get CSS classes for remote video based on state
   */
  getRemoteVideoClasses(): { [key: string]: boolean } {
    return {
      'remote-video': true,
      'embed-responsive-item': true,
      'rounded-sm': true,
      'shadow-sm': true,
      'w-100': true,
      'h-100': true,
      'd-none': !this.remoteStream
    };
  }
}
