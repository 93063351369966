import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ValiInfo } from '../ValiInfo';
import { SelectedValues } from '../SelectedValues';
import { DatesService } from '../services/dates.service';

@Component({
    selector: 'app-step4',
    templateUrl: './step4.component.html',
    styleUrls: ['./step4.component.css'],
    standalone: false
})
export class Step4Component implements OnInit {

  pinChecker = true;
  ongoingRequest = false;

  @Input() selectedValues: SelectedValues;
  @Input() verification: ValiInfo;
  @Output() nextStep = new EventEmitter<number>();
  @Input() color;
  @Input() isVAMED?: boolean;
  @Input() physicianPicture?: string;
  @Input() personPicture?: string;
  // verification: Verification = {pin: '938267', taskID: '1728'};

  constructor(private datesService: DatesService) { }

  ngOnInit() {
  }

  goTo(step: number): void {
    this.nextStep.emit(step);
  }

  validPin(i: any) {
    if (i.valid) {
      console.log('PIN was correct');
      this.pinChecker = true;
      this.goTo(5);
      // setTimeout(() => {
      //   this.nextStep.emit(1);
      // }, 15000);
    } else {
      console.log('PIN was wrong');
      this.pinChecker = false;
    }
  }

  async onSubmit() {
    try {
      this.ongoingRequest = true;
      const temp = await this.datesService.verification(this.verification);
      this.validPin(temp);
    } catch (err) {
      console.log(err);
    } finally {
      this.ongoingRequest = false;
    }
  }
}
