import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

@Pipe({
    name: 'dayjsDate',
    standalone: false
})
export class DayJSDatePipe extends DatePipe implements PipeTransform {
  override transform(value: null, format?: string, timezone?: string, locale?: string): null;
  override transform(value: string | number | Date, format?: string, timezone?: string, locale?: string): string;
  // tslint:disable-next-line:max-line-length
  override transform(value: string | number | Date | null, format: string = 'mediumDate', timezone: string = 'Europe/Vienna', locale?: string): string | null {
    if (value === null) {
      return null;
    }

    const timezoneOffset = dayjs(value).tz(timezone).format('Z');
    return super.transform(value, format, timezoneOffset, locale) || null;
  }
}
