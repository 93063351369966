import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDeAt from '@angular/common/locales/de-AT';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgbsComponent } from './book-appointment/agbs/agbs.component';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { DatenschutzComponent } from './book-appointment/datenschutz/datenschutz.component';
import { DatesService } from './book-appointment/services/dates.service';
import { RoomsService } from './book-appointment/services/rooms.service';
import { Step1Component } from './book-appointment/step1/step1.component';
import { Step2Component } from './book-appointment/step2/step2.component';
import { Step3Component } from './book-appointment/step3/step3.component';
import { Step4Component } from './book-appointment/step4/step4.component';
import { Step5Component } from './book-appointment/step5/step5.component';
import { DayJSDatePipe } from './dayjs-date.pipe';
import { SvnrcheckDirective } from './directives/svnrcheck.directive';
import { VideoCallModule } from './feature/video-call/video-call.module';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { PaymentCardInfoComponent } from './payment/payment-card-info/payment-card-info.component';
import { PaymentResultComponent } from './payment/payment-result/payment-result.component';

registerLocaleData(localeDeAt);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    BookAppointmentComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    SvnrcheckDirective,
    AgbsComponent,
    DatenschutzComponent,
    PaymentCardInfoComponent,
    PaymentResultComponent,
    DayJSDatePipe
  ],
  bootstrap: [AppComponent],
  imports: [AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    VideoCallModule],
  providers: [{ provide: LOCALE_ID, useValue: 'de-AT' }, DatesService, RoomsService, provideHttpClient(withInterceptorsFromDi())]
})
export class AppModule {
}
