import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { VideoCallService } from '../../services/video-call.service';
import { take, filter, tap, map, exhaustMap, catchError, takeUntil, switchMap } from 'rxjs/operators';
import { Observable, of, throwError, BehaviorSubject, interval, defer, Subject } from 'rxjs';
import { IPCAuthResult } from '../../clients/video-call-client.service';
import { VideoCallSocketService, ConnectionState } from '../../services/video-call-socket.service';

class InitStreamsError extends Error {
  type = 'INIT_ERROR';

  constructor(message: string) {
    super(message);
  }
}

const INIT_ACCESS_ERR_MSG =
  'Bitte überprüfen Sie die Verfügbarkeit Ihrer Webcam / Ihres Mikrofons und stellen Sie sicher, dass dieser Browser darauf Zugriff hat.\
   \r\nVergewissern Sie sich bitte auch, dass Ihre Webcam und Ihr Mikrofon aktuell von keinem anderen Programm verwendet werden.';

const BROWSER_NOT_SUPPORTED_MSG =
  'Ihr Browser unterstützt leider keine Video-Anrufe. Bitte verwenden Sie einen modernen Browser wie Chrome, Firefox, Edge oder Safari.';

@Component({
  selector: 'app-video-call-view',
  templateUrl: './video-call-view.component.html',
  styleUrls: ['./video-call-view.component.css'],
  standalone: false
})
export class VideoCallViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('video') videoEl: ElementRef;
  @ViewChild('remoteVideo') remoteVideoEl: ElementRef;
  @ViewChild('drawer') drawerEl: MatDrawer;

  // Session variables
  private sessionId: string;
  private sessionPw: string;
  private sessionToken: string;
  private pcConfig: RTCConfiguration;
  private destroy$ = new Subject<void>();

  // Stream observables
  localStream$: Observable<MediaStream>;
  remoteStream$: Observable<MediaStream>;
  connectionState$: Observable<ConnectionState>;

  // UI control variables
  micEnabled = true;
  videoEnabled = true;
  hasPeerConnection$$ = new BehaviorSubject<boolean>(false);
  timer$: Observable<{ minutes: number; seconds: number }>;
  callStartTime: number | null = null;

  // Authentication and error handling
  authRequest$: Observable<IPCAuthResult>;
  errorObject: Error | null = null;

  // Chat
  chatMessages$: Observable<any[]>;

  unreadMessageCount = 0;

  mediaStreamError = false;

  constructor(
    private route: ActivatedRoute,
    private videoCallService: VideoCallService,
    private socketService: VideoCallSocketService
  ) {
  }

  ngOnInit(): void {
    // Check browser compatibility
    if (!this.videoCallService.isWebRTCSupported()) {
      this.errorObject = new InitStreamsError(BROWSER_NOT_SUPPORTED_MSG);
      return;
    }

    // Get route parameters
    this.sessionId = this.route.snapshot.paramMap.get('id');
    this.sessionPw = this.route.snapshot.queryParamMap.get('pw');

    // Set up socket service observables
    this.chatMessages$ = this.socketService.chatMessages$;
    this.localStream$ = this.socketService.localStream$;
    this.connectionState$ = this.socketService.connectionState$;

    // Set up remote stream with connection state handling
    this.remoteStream$ = this.socketService.remoteStream$.pipe(
      tap(stream => {
        this.hasPeerConnection$$.next(!!stream);

        // Start timer when stream is connected
        if (stream && !this.callStartTime) {
          this.callStartTime = Date.now();
          this.initializeTimer();
        }

        // Reset timer when stream is disconnected
        if (!stream) {
          this.callStartTime = null;
          this.timer$ = null;
        }
      }),
      takeUntil(this.destroy$)
    );

    // Set up authentication request
    this.authRequest$ = this.videoCallService.callAuthenticate(this.sessionId, this.sessionPw).pipe(
      tap(result => {
        this.sessionToken = result.token;
        this.pcConfig = result.pcConfig;
      }),
      exhaustMap(result => defer(() => this.initializeCall()).pipe(map(() => result))),
      catchError(err => {
        this.errorObject = err;
        return throwError(() => err);
      }),
      takeUntil(this.destroy$)
    );

    // Monitor connection state
    this.connectionState$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state === ConnectionState.Failed)
      )
      .subscribe(() => {
        this.errorObject = new Error('Die Verbindung wurde unterbrochen. Bitte versuchen Sie, den Anruf neu zu starten.');
      });

    this.chatMessages$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(messages => {
      if (messages.length > 0 &&
        (!this.drawerEl || !this.drawerEl.opened) &&
        messages[messages.length - 1].sender === '_remote') {
        this.unreadMessageCount++;
      }
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    // Clean up resources
    this.destroy$.next();
    this.destroy$.complete();
    this.closeConnection();
  }

  /**
   * Initialize the call timer
   */
  private initializeTimer(): void {
    this.callStartTime = Date.now();

    this.timer$ = interval(1000).pipe(
      takeUntil(this.destroy$),
      map(() => {
        if (!this.callStartTime) {
          return { minutes: 0, seconds: 0 };
        }

        const elapsedSeconds = Math.floor((Date.now() - this.callStartTime) / 1000);
        return {
          minutes: Math.floor(elapsedSeconds / 60),
          seconds: elapsedSeconds % 60
        };
      })
    );
  }

  /**
   * Toggle microphone on/off
   */
  toggleMic(isEnabled: boolean): void {
    this.socketService.setMicEnabled(isEnabled);
    this.micEnabled = isEnabled;
  }

  /**
   * Toggle video on/off
   */
  toggleVideo(isEnabled: boolean): void {
    this.socketService.setVideoEnabled(isEnabled);
    this.videoEnabled = isEnabled;
  }

  /**
   * Send chat message
   */
  sendChatMessage(message: string): void {
    this.socketService.sendChatMessage(message);
  }

  /**
   * Close the WebRTC connection
   */
  closeConnection(): void {
    this.socketService.closeConnection();
    this.hasPeerConnection$$.next(false);
    this.callStartTime = null;
  }

  /**
   * Initialize the call
   */
  initializeCall(): Promise<void> {
    if (!this.sessionToken || !this.pcConfig) {
      return Promise.reject(new Error('Missing session token or PC config'));
    }

    return this.videoCallService
      .getLocalMediaStream()
      .then(localStream => {
        // current mic and video states
        const tracks = localStream.getTracks();
        tracks.forEach(track => {
          if (track.kind === 'audio') {
            track.enabled = this.micEnabled;
          } else if (track.kind === 'video') {
            track.enabled = this.videoEnabled;
          }
        });

        return this.socketService.initSocket(this.sessionToken, this.pcConfig, localStream);
      })
      .catch(err => {
        console.error('Error initializing media stream:', err);
        this.mediaStreamError = true;
        throw new InitStreamsError(INIT_ACCESS_ERR_MSG);
      });
  }

  /**
   * Handle retry attempt from error screen
   */
  retryConnection(): void {

    if (this.mediaStreamError) {
      window.history.go(0);
    }

    // Clear previous error
    this.errorObject = null;

    // Re-authenticate if token is missing
    if (!this.sessionToken || !this.pcConfig) {
      // Re-initialize the authentication process
      this.authRequest$ = this.videoCallService.callAuthenticate(this.sessionId, this.sessionPw).pipe(
        tap(result => {
          this.sessionToken = result.token;
          this.pcConfig = result.pcConfig;
        }),
        exhaustMap(result => defer(() => this.initializeCall()).pipe(map(() => result))),
        catchError(err => {
          this.errorObject = err;
          return throwError(() => err);
        }),
        takeUntil(this.destroy$)
      );
    } else {
      // If we already have token and config, just initialize the call
      this.initializeCall().catch(err => {
        this.errorObject = err;
      });
    }
  }

  handleReconnect(): void {
    // Store current states before closing connection
    const currentMicState = this.micEnabled;
    const currentVideoState = this.videoEnabled;

    // Close the existing connection
    this.closeConnection();

    // Wait a moment before attempting to reconnect
    setTimeout(() => {
      // Update component properties to reflect current states
      this.micEnabled = currentMicState;
      this.videoEnabled = currentVideoState;

      // Re-initialize the call with existing credentials
      this.initializeCall().catch(err => {
        console.error('Reconnect failed:', err);
        // If reconnection fails, try full authentication again
        this.retryConnection();
      });
    }, 1000);
  }

  resetUnreadMessageCount(): void {
    this.unreadMessageCount = 0;
  }
}
