<div class="row justify-content-center w-100">
  <div class="btn-toolbar d-flex justify-content-center">
    <!-- Timer Display using Bootstrap equivalent classes -->
    <div
      class="alert alert-secondary text-white d-flex align-items-center justify-content-center me-2 p-0 m-0 fixed-size-timer">
      <span *ngIf="timer; else noTimer">
        {{ formatTime(timer.minutes) }}:{{ formatTime(timer.seconds) }}
      </span>
      <ng-template #noTimer>
        <span>00:00</span>
      </ng-template>
    </div>

    <!-- Control Buttons with Bootstrap classes -->
    <div class="btn-group">
      <!-- Video Toggle Button -->
      <button
        class="btn btn-secondary d-flex align-items-center justify-content-center p-0 fixed-size-button"
        (click)="onToggleVideo(videoEnabled)"
        title="Webcam aktivieren/deaktivieren"
        [class.active]="!videoEnabled">
        <mat-icon *ngIf="videoEnabled; else disabledVid">videocam</mat-icon>
        <ng-template #disabledVid>
          <mat-icon>videocam_off</mat-icon>
        </ng-template>
      </button>

      <!-- Microphone Toggle Button -->
      <button
        class="btn btn-secondary d-flex align-items-center justify-content-center p-0 fixed-size-button"
        (click)="onToggleMic(micEnabled)"
        title="Mikrofon aktivieren/deaktivieren"
        [class.active]="!micEnabled">
        <mat-icon *ngIf="micEnabled; else disabledMic">mic</mat-icon>
        <ng-template #disabledMic>
          <mat-icon>mic_off</mat-icon>
        </ng-template>
      </button>

      <!-- Chat Button -->
      <button
        class="btn btn-secondary d-flex align-items-center justify-content-center p-0 position-relative fixed-size-button"
        (click)="onToggleChat()"
        title="Chat öffnen/schließen"
        [class.active]="drawerEl.opened">
        <mat-icon>comment</mat-icon>
        <span *ngIf="unreadMessageCount > 0" class="position-absolute badge rounded-pill bg-danger notification-badge d-flex align-items-center justify-content-center">
    {{ unreadMessageCount }}
  </span>
      </button>

      <!-- End Call Button -->
      <button
        class="btn btn-danger d-flex align-items-center justify-content-center p-0 fixed-size-button"
        (click)="onCloseConnection()"
        title="Sitzung verlassen">
        <mat-icon>call_end</mat-icon>
      </button>

      <!-- Reconnect Button (shows when connection is lost) -->
      <button
        *ngIf="showReconnect"
        class="btn btn-warning d-flex align-items-center justify-content-center p-0 fixed-size-button"
        (click)="onOpenConnection()"
        title="Verbindung wiederherstellen">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
</div>
