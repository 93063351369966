import { AfterViewInit, Component, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigService } from '../config.service';
import { PaymentService } from '../payment.service';

@Component({
    selector: 'app-payment-card-info',
    templateUrl: './payment-card-info.component.html',
    styleUrls: ['./payment-card-info.component.css'],
    standalone: false
})
export class PaymentCardInfoComponent implements AfterViewInit {

  paymentForm: SafeHtml;
  @ViewChild('paymentScript') paymentScript;
  patient;

  constructor(private activatedRoute: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private renderer: Renderer2,
              private paymentService: PaymentService,
              private config: ConfigService,
              private location: Location) {
    this.paymentForm = this.sanitizer.bypassSecurityTrustHtml(`<form action="${config.clientPath}payment/result" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>`);
  }


  ngAfterViewInit(): void {
    this.paymentService.requestPayment().subscribe(data => {
      const url = `${this.config.gatewayPath}v1/paymentWidgets.js?checkoutId=${data.id}`;
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'src', url);
      this.renderer.appendChild(this.paymentScript.nativeElement, script);
    });

  }

  goBack(): void {
    this.location.back();
  }
}
