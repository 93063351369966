import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

/**
 * Component for video call controls menu
 */
@Component({
  selector: 'app-video-call-menu',
  templateUrl: './video-call-menu.component.html',
  styleUrls: ['./video-call-menu.component.css'],
  standalone: false
})
export class VideoCallMenuComponent implements OnInit, OnChanges {
  /**
   * Flag indicating if there is an active peer connection
   */
  @Input()
  hasPeerConnection: boolean | null = false;

  /**
   * Reference to the chat drawer
   */
  @Input()
  drawerEl!: MatDrawer;

  /**
   * Call timer data
   */
  @Input()
  timer: { minutes: number; seconds: number } | null = null;

  /**
   * Initial mic state - if provided, will override the default
   */
  @Input()
  initialMicState: boolean = true;

  /**
   * Initial video state - if provided, will override the default
   */
  @Input()
  initialVideoState: boolean = true;

  /**
   * Event emitted when microphone is toggled
   */
  @Output()
  toggleMic = new EventEmitter<boolean>();

  /**
   * Event emitted when video is toggled
   */
  @Output()
  toggleVideo = new EventEmitter<boolean>();

  /**
   * Event emitted when connection is closed
   */
  @Output()
  closePeerConnection = new EventEmitter<void>();

  /**
   * Event emitted when connection is opened
   */
  @Output()
  openConnection = new EventEmitter<void>();


  /**
   * Unread message count
   */
  @Input()
  unreadMessageCount = 0;

  /**
   * Event emitted when messages are read
   */
  @Output()
  messagesRead = new EventEmitter<void>();

  /**
   * Current microphone state
   */
  micEnabled = true;

  /**
   * Current video state
   */
  videoEnabled = true;

  /**
   * Show reconnect button
   */
  showReconnect = false;

  constructor() {
  }

  ngOnInit(): void {
    // Initialize states from inputs if provided
    this.micEnabled = this.initialMicState;
    this.videoEnabled = this.initialVideoState;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // React to hasPeerConnection changes
    if (changes['hasPeerConnection'] && !changes['hasPeerConnection'].firstChange) {
      this.showReconnect = changes['hasPeerConnection'].currentValue === false &&
        changes['hasPeerConnection'].previousValue === true;
    }
  }

  /**
   * Close the peer connection
   */
  onCloseConnection(): void {
    this.closePeerConnection.emit();
  }

  /**
   * Open or restart the connection
   */
  onOpenConnection(): void {
    this.openConnection.emit();
    this.showReconnect = false;
  }

  /**
   * Toggle microphone state
   */
  onToggleMic(isEnabled: boolean): void {
    this.micEnabled = !isEnabled;
    this.toggleMic.emit(!isEnabled);
  }

  /**
   * Toggle video state
   */
  onToggleVideo(isEnabled: boolean): void {
    this.videoEnabled = !isEnabled;
    this.toggleVideo.emit(!isEnabled);
  }

  /**
   * Format timer display
   */
  formatTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  /**
   * Handle opening of chat drawer and reset message count
   */
  onToggleChat(): void {
    if (!this.drawerEl.opened) {
      this.drawerEl.open();
      this.messagesRead.emit();
    } else {
      this.drawerEl.close();
    }
  }
}
