import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../payment.service';
import { PaymentResultModel } from '../payment.result.model';

@Component({
    selector: 'app-payment-result',
    templateUrl: './payment-result.component.html',
    styleUrls: ['./payment-result.component.css'],
    standalone: false
})
export class PaymentResultComponent implements OnInit {
  paymentResult: PaymentResultModel;

  constructor(private activatedRoute: ActivatedRoute,
              private paymentService: PaymentService) {
  }

  ngOnInit(): void {
    const paymentId = this.activatedRoute.snapshot.queryParamMap.get('id');
    console.log(paymentId);
    this.paymentService.add(paymentId).subscribe(data => {
      this.paymentResult = data;
    });
  }
}
