import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-datenschutz',
    templateUrl: './datenschutz.component.html',
    styleUrls: ['./datenschutz.component.css'],
    standalone: false
})
export class DatenschutzComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
