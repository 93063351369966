import { Directive, Input, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SelectedValues } from '../book-appointment/SelectedValues';

@Directive({
    selector: '[appValidateSVNR]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SvnrcheckDirective, multi: true }],
    standalone: false
})

export class SvnrcheckDirective implements Validator {

  @Input('appValidateSVNR') validateSVNR: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.validateSVNR ? forbiddenNameValidator(new RegExp(this.validateSVNR, 'i'))(control)
      : null;
  }
}

// export function validateSVNRValidator(values: SelectedValues): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: any } | null => {
//     let forbidden;

//     if (values.EventTypeID === '1') {
//       forbidden = false;
//     } else {
//       forbidden = true;
//     }

//     return forbidden ? { 'validateSVNR': { value: control.value } } : null;
//   };
// }

export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? { 'forbiddenName': { value: control.value } } : null;
  };
}