<div class="position-relative h-100 w-100 d-flex justify-content-center align-items-center">
  <!-- Remote video takes most of the space -->
  <div class="embed-responsive embed-responsive-16by9 w-100 h-100">
    <video
      [ngClass]="getRemoteVideoClasses()"
      #remoteVideo
      autoplay
      playsinline
    ></video>

    <!-- Display a message when no remote stream is available but we're waiting for a participant -->
    <div *ngIf="!remoteStream && isWaitingForParticipant"
         class="waiting-message text-center text-white position-absolute top-50 start-50 translate-middle">
      <div class="p-4 bg-dark bg-opacity-75 rounded">
        <h3>Herzlich willkommen!</h3>
        <p>Warte auf weitere Teilnehmer...</p>
        <div class="mt-3">
          <i class="fas fa-user-clock fa-2x"></i>
        </div>
        <p class="mt-3 small">Die Verbindung wird automatisch hergestellt, sobald ein weiterer Teilnehmer beitritt.</p>
      </div>
    </div>

    <!-- Display a message when no remote stream is available and we're not waiting -->
    <div *ngIf="!remoteStream && !isWaitingForParticipant"
         class="waiting-message text-center text-white position-absolute top-50 start-50 translate-middle">
      <div class="p-4 bg-dark bg-opacity-75 rounded">
        <h3>Warte auf Verbindung...</h3>
        <p>Es muss ein weiterer Teilnehmer dem Anruf beitreten</p>
        <div class="spinner-border text-light mt-2" role="status">
          <span class="visually-hidden">Warte...</span>
        </div>
      </div>
    </div>

    <!-- Connection error message -->
    <div
      *ngIf="connectionError"
      class="error-message text-center text-white position-absolute top-50 start-50 translate-middle">
      <div class="p-4 bg-danger bg-opacity-75 rounded">
        <h3><i class="fas fa-exclamation-triangle me-2"></i>Verbindungsproblem</h3>
        <p>Die Verbindung zum anderen Teilnehmer konnte nicht hergestellt werden</p>
        <div class="d-flex justify-content-center">
          <button
            type="button"
            class="button-custom w-50 d-flex justify-content-center align-items-center"
            (click)="onReconnectClick()"
          >
            <mat-icon>refresh</mat-icon>
            Mehr anzeigen
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Local video in bottom right corner -->
  <div class="position-absolute bottom-0 end-0 m-3 local-video-container border-5 border-light">
    <div class="position-relative">
      <video
        [ngClass]="getLocalVideoClasses()"
        muted
        #localVideo
        autoplay
        playsinline
      ></video>

      <!-- Overlay for disabled video -->
      <div *ngIf="!videoEnabled && localStream"
           class="video-disabled-overlay d-flex justify-content-center align-items-center">
        <i class="fas fa-video-slash fa-2x text-white"></i>
      </div>
    </div>
  </div>
</div>
