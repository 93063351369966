import { Injectable } from '@angular/core';
import { VideoCallClientService, IPCAuthResult } from '../clients/video-call-client.service';
import { Observable, from } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Service for handling video call functionality
 * This service manages authentication and media stream acquisition
 */
@Injectable({
  providedIn: 'root'
})
export class VideoCallService {
  constructor(private videoCallClient: VideoCallClientService) {
  }

  /**
   * Authenticate with the video call service
   * @param room Room identifier
   * @param pw Room password
   * @returns Observable with authentication result
   */
  callAuthenticate(room: string, pw: string): Observable<IPCAuthResult> {
    return this.videoCallClient.authenticate(room, pw);
  }

  /**
   * Get local media stream for video and audio
   * @param options Optional configuration to override defaults
   * @returns Promise that resolves to a MediaStream
   */
  getLocalMediaStream(options?: {
    audio?: boolean | MediaTrackConstraints;
    video?: boolean | MediaTrackConstraints;
  }): Promise<MediaStream> {
    const defaultConstraints: MediaStreamConstraints = {
      video: {
        width: { min: 320, ideal: 720, max: 1280 },
        height: { min: 240, ideal: 540, max: 720 },
        facingMode: 'user',
        frameRate: { ideal: 30, max: 60 }
      },
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
        sampleRate: { ideal: 48000 },
        sampleSize: { ideal: 16 },
        channelCount: { ideal: 2 },
      }
    };

    console.log('getLocalMediaStream', options);
    const constraints: MediaStreamConstraints = {
      video: options?.video ?? defaultConstraints.video,
      audio: options?.audio ?? defaultConstraints.audio
    };

    return navigator.mediaDevices.getUserMedia(constraints);
  }

  /**
   * Get screen sharing stream
   * @returns Promise that resolves to a MediaStream
   */
  getScreenSharingStream(): Promise<MediaStream> {
    // TypeScript doesn't have built-in types for getDisplayMedia
    // so we need to use the 'any' type here
    const mediaDevices = navigator.mediaDevices as any;

    if (!mediaDevices.getDisplayMedia) {
      return Promise.reject(new Error('Screen sharing not supported in this browser'));
    }

    return mediaDevices.getDisplayMedia({
      video: {
        cursor: 'always',
        displaySurface: 'monitor'
      },
      audio: false
    });
  }

  /**
   * Check if the browser supports WebRTC
   * @returns Boolean indicating WebRTC support
   */
  isWebRTCSupported(): boolean {
    return !!(
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia &&
      window.RTCPeerConnection
    );
  }
}
