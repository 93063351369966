import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCallViewComponent } from './containers/video-call-view/video-call-view.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { ChatComponent } from './components/chat/chat.component';

import 'webrtc-adapter';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { VideoCallService } from './services/video-call.service';
import { VideoCallClientService } from './clients/video-call-client.service';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { VideoCallMenuComponent } from './components/video-call-menu/video-call-menu.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  declarations: [VideoCallViewComponent, ChatComponent, LinkifyPipe, VideoCallComponent, VideoCallMenuComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatChipsModule
  ], providers: [VideoCallClientService, VideoCallService]
})
export class VideoCallModule {
}
