import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

interface ChatMessage {
  message: string;
  sender: string;
  timestamp?: number;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  standalone: false
})
export class ChatComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy {
  /**
   * Reference to the chat drawer
   */
  @Input()
  drawerEl!: MatDrawer;

  /**
   * Array of chat messages to display
   */
  @Input()
  chatMessages: ChatMessage[] = [];

  /**
   * Event emitted when user sends a new message
   */
  @Output()
  sendChatMessage = new EventEmitter<string>();

  /**
   * Reference to the scroll element at the bottom of messages
   */
  @ViewChild('scrollBottom', { static: false })
  private scrollBottom!: ElementRef;

  /**
   * Current message being typed by user
   */
  currentMessage = '';

  /**
   * Flag to determine if auto-scroll should happen
   */
  shouldScrollToBottom = true;

  /**
   * Keep track of previous message count for auto-scroll decision
   */
  private previousMessageCount = 0;

  /**
   * Track if user has manually scrolled up
   */
  userHasScrolledUp = false;

  /**
   * Track if component is destroyed
   */
  private isDestroyed = false;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.currentMessage = '';
  }

  ngAfterViewChecked(): void {
    if (this.shouldScrollToBottom && !this.userHasScrolledUp) {
      this.scrollToBottom();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chatMessages'] && this.chatMessages) {
      // Determine if we should auto-scroll
      const newMessages = this.previousMessageCount < this.chatMessages.length;

      // Always scroll if it's a message from the current user
      const isOwnMessage = this.chatMessages.length > 0 &&
        this.chatMessages[this.chatMessages.length - 1]?.sender === '_self';

      this.shouldScrollToBottom = newMessages && (isOwnMessage || !this.userHasScrolledUp);
      this.previousMessageCount = this.chatMessages.length;
    }
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  /**
   * Handle sending a new message
   */
  onSendClick(message: string): void {
    if (!message?.trim()) {
      return;
    }

    this.currentMessage = '';
    this.sendChatMessage.emit(message);
    this.shouldScrollToBottom = true;
    this.userHasScrolledUp = false;

    // Force immediate scroll after sending
    setTimeout(() => {
      if (!this.isDestroyed) {
        this.scrollToBottom();
        this.cdr.detectChanges();
      }
    }, 0);
  }

  /**
   * Track user scroll to detect when they scroll up manually
   */
  onChatScroll(event: Event): void {
    const element = event.target as HTMLElement;

    // Check if user has scrolled up
    const atBottom = Math.abs(
      element.scrollHeight - element.scrollTop - element.clientHeight
    ) < 50; // Allow small margin of error

    this.userHasScrolledUp = !atBottom;

    // If user has scrolled to bottom, re-enable auto-scroll
    if (atBottom) {
      this.shouldScrollToBottom = true;
    }
  }

  /**
   * Format timestamp to readable time
   */
  formatTimestamp(timestamp?: number): string {
    if (!timestamp) {
      return '';
    }

    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  /**
   * Determine message sender display
   */
  getSenderLabel(sender: string): string {
    return sender === '_self' ? 'You' : 'Other participant';
  }

  /**
   * Scroll to the bottom of the chat
   */
  scrollToBottom(): void {
    try {
      this.scrollBottom.nativeElement.scrollIntoView({ behavior: 'smooth' });
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }
}
