import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-agbs',
    templateUrl: './agbs.component.html',
    styleUrls: ['./agbs.component.css'],
    standalone: false
})
export class AgbsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
